/* Custom functions on bottom /*

/* * * * * * * * * */
/* Base URL        */
/* * * * * * * * * */

  fx_base = {

    _construct_main_container: function($onlyVariables){
      $footer_container = $('footer');
      $html_container   = $('html');
      $smain_container  = $('.smain-container');

      if(!$onlyVariables){
        $smain_container.css({
          'min-height' : 'auto',
          'height'     : 'auto',
        });
      }

      footer_height                = $footer_container.outerHeight();
      site_height                  = $html_container.outerHeight();
      smain_container_height       = $smain_container.outerHeight();
      smain_container_real_height  = $smain_container[0].scrollHeight;

      site_height_without_footer   = site_height;
      site_height_with_footer      = site_height - footer_height;
    },

    _returnVariables: function(){
      this._construct_main_container(true);

      //--

      $(window).resize(function() {
        fx_base._construct_main_container(true);
      });

      //--

      return {
        'footer_height'               : footer_height,
        'site_height'                 : site_height,
        'site_height_without_footer'  : site_height_without_footer,
        'site_height_with_footer'     : site_height_with_footer,
      };
    },

    getUrl: function(){
      pathArray = window.location.href.split( '/' );
      protocol  = pathArray[0];
      host      = pathArray[2];
      url       = protocol + '//' + host;

      //--

      return url;
    },

    mainContainer: function() {

      /* This function defines the height of
      the main container by placing the footer
      at the bottom of the site, use the corresponding
      classes according to your needs. */

      this._construct_main_container();

      //--

      $smain_container // Default // Min height // With Footer
        .css('min-height', site_height_with_footer + 'px');

      if($smain_container.hasClass('min-full-screen')) // Min height // Without Footer
        $(this).css('min-height', site_height_without_footer + 'px');

      if($smain_container.hasClass('max-screen')) // Max height // With Footer
        $(this).css('height', site_height_with_footer + 'px');

      if($smain_container.hasClass('max-full-screen')) // Max height // Without Footer
        $(this).css('height', site_height_without_footer + 'px');

      // Fixing absolute

      if($smain_container.hasClass('max-screen') || $smain_container.hasClass('max-full-screen')){

        if(smain_container_height <= smain_container_real_height && site_height <= smain_container_real_height){

          $smain_container
          .css('min-height', smain_container_real_height);
        }
      }
    },
  }

  var baseUrl = fx_base.getUrl(); // -> Use This

/* * * * * * * * * */

/* * * * * * * * * */
/* messages        */
/* * * * * * * * * */

  fx_messages = {

    _construct: function(){
      $messages = $('.messages');
      $btn_close = $messages.find('.close');
      $alert = $messages.find('.alert');
    },

    _hide: function(event){

      event = typeof event !== 'undefined' ? event : null;

      event.preventDefault();
      event.stopPropagation();

      $messages
      .stop()
      .fadeOut();
    },

    make: function(){
      this._construct();

      //--

      $messages
      .on('click', function(event){
        fx_messages._hide(event);
      });

      $alert
      .on('click', function(event){
        event.stopPropagation();
      });

      $btn_close
      .on('click', function(event){
        fx_messages._hide(event);
      })
    },
  }

/* * * * * * * * * */
/* Proportionaly   */
/* * * * * * * * * */

  /*If you are using squares or rectangles,
  these functions can help maintain the minimum
  or maximum proportion of the container to which
  the class is assigned.*/

  fx_proportional = {

    _construct: function(){
      $proportional = $('.proportional'); // -> Use this
    },

    _square: function(){
      $proportional
      .each(function(index){
        $object = $(this);
        if($object.hasClass('square')){ // -> Use this
          proportional_width  = $object[0].getBoundingClientRect().width;
          $object
          .css('min-height', proportional_width + 'px');
        }
      });

      $proportional
      .each(function(index){
        $object = $(this);
        if($object.hasClass('square-max')){ // -> Use this
          proportional_width  = $object[0].getBoundingClientRect().width;
          $object
          .css('height', proportional_width + 'px');
        }
      });
    },

    _rectangle: function(){
      $proportional
      .each(function(index){
        $object = $(this);
        if($object.hasClass('rectangle')){ // -> Use this
          proportional_width = Math.round($object[0].getBoundingClientRect().width / 2);
          $object
          .css('min-height', proportional_width + 'px');
        }
      });

      $proportional
      .each(function(index){
        $object = $(this);
        if($object.hasClass('rectangle-max')){ // -> Use this
          proportional_width = Math.round($object[0].getBoundingClientRect().width / 2);
          $object
          .css('height', proportional_width + 'px');
        }
      });
    },

    make: function(){
      this._construct();

      fx_proportional._square();
      fx_proportional._rectangle();

      $(window)
      .resize(function(){
        fx_proportional._square();
        fx_proportional._rectangle();
      });
    }
  }

/* * * * * * * * * * * */

/** Menu **/

  fx_menu = {

    _construct: function(){
      $html       = $('html');
      $body       = $('.smain-container, footer');
      $open_menu  = $('.mobile a');
      $nav        = $('.main-menu nav');
    },

    _actions: function(event){
      this._construct();

      //--

      if(event){
        event.preventDefault();
        event.stopPropagation();
      }

      $open_menu
      .stop()
      .toggleClass('active');

      $body
      .stop()
      .toggleClass('active');

      $nav
      .stop()
      .toggleClass('active');

    },

    make: function(){
      this._construct();

      //--

      $open_menu
      .on('click', function(event){
        fx_menu._actions(event);
      });

      $nav
      .on('click', function(event){
        event.stopPropagation();
      });

      $html
      .on('click', function(event){
        if($nav.hasClass('active')){
          fx_menu._actions();
        }
      });
    }
  }

/** Sliders **/

  fx_sliders = {

    _construct: function(){
      this.$slider_home = $('.slider-home');
      this.$slider_testimonials = $('.slick-testimonials');
    },

    // ---

    home: function(){
      this._construct();

      //--

      this.$slider_home
      .slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    },

    testimonials: function(){
      this._construct();

      this.$slider_testimonials
      .slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    }
  }

/** Dot Dot Dot JS **/

fx_dot_js = {
  go: function(){
    /*$('.t-content')
    .dotdotdot({
  		height: 100,
  		fallbackToLetter: true,
  		watch: true
  	});*/

    // let wrapper = document.querySelector(".t-content")
    let wrappers = document.querySelectorAll(".t-content")
    let options = {
      ellipsis: "\u2026 ",
      height: 100,
      tolerance: 0,
      truncate: "word",
      watch: "window",
    }

    wrappers.forEach((wrapper) => {
      new Dotdotdot(wrapper, options)
    })
  }

}


/** Symmetrical containers **/

  fx_symmetrical = {

    _construct: function(){
      $symmetrical_container = $('.symmetrical-container');
    },

    _symmetrical: function(){
      this._construct();

      //--

      $symmetrical_container
      .each(function(i){

        a = 0;
        b = 0;

        $symmetrical = $(this).find('.symmetrical');

        $symmetrical
        .each(function(j){

          $symmetrical
          .css('height', 'auto');

          a = $(this).outerHeight();

          if(a >= b){
            b = a;
          }

          $symmetrical
          .css('height', b + 'px');

        });

      });
    },

    make: function(){

      fx_symmetrical._symmetrical();

      $(window)
      .resize(function() {
        fx_symmetrical._symmetrical();
      });

      $(window).load(function() {
        fx_symmetrical._symmetrical();
      });
    }
  }

/** Inputs **/

  fx_inputs = {

    _construct: function(){
      $fields       = $('input, select, textarea');
      $textarea     = $('textarea');
      $only_numbers = $('input[type="only_number"]');
    },

    _validateActions: function($input_field){
      //-- active
      if($input_field.val()){
        $input_field.addClass('active');
      }
      else{
        $input_field.removeClass('active');
      }
      //--
    },

    validate: function(){
      this._construct();

      //--

      $only_numbers
      .on('keydown keyup', function(event){
        key_id = event.keyCode;
        if(key_id != 8 && key_id != 46){
          supposed_number = this.value;
          this.value = supposed_number.replace(/[^0-9\.\,\-]/g,"");
        }
      });

      $fields.each(function(index){
        fx_inputs._validateActions($(this));
      });

      $fields.on('change', function(event){
        fx_inputs._validateActions($(this));
      });
    }
  }

/** Instagram **/

  fx_instagram = {

    _construct: function(){
      $instagram          = $('.instagram');
      $instagram_template = $('#instagram-template');
    },

    load: function(){
      this._construct();

      //--

      $instagram
      .on('willLoadInstagram', function(event, options) {  });

      $instagram
      .on('didLoadInstagram', function(event, response) {
        var compiled = _.template($instagram_template.html());
        $(this).html(compiled({ items: response.data }));
      });

      $instagram
      .instagram({
        userId: '3026184888',
        accessToken: '3026184888.07d50fa.6c990e6ff425461d9783728472646ee8',
        count: 6,
      });
    }
  }

/** components **/

  fx_components = {

    _construct: function(){
      $dropdown         = $('.dropdown');
      $drop_element     = $dropdown.find('.drop-element');
      $drop_description = $dropdown.find('.drop-description');
      $date_field       = $("input[name='datepicker']");
    },

    datepicker: function(){
      this._construct();

      //--
      $.datepicker.regional['es'] = {
        closeText: 'Cerrar',
        prevText: '<i class="fa fa-chevron-left"></i>',
        nextText: '<i class="fa fa-chevron-right"></i>',
        currentText: 'Hoy',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
        dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
        weekHeader: 'Sm',
        dateFormat: 'dd-mm-yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
         };
      $.datepicker.setDefaults($.datepicker.regional['es']);

      $date_field
      .datepicker();
    },

    dropdown: function(){
      this._construct();

      //--

      $drop_element
      .on('click', function(event){
        event.preventDefault();
        if(!$dropdown.hasClass('always-open')){
          $drop_element
          .removeClass('active')
          .find('.drop-description')
          .stop()
          .slideUp('fast');
        }
        $(this)
        .toggleClass('active')
        .find('.drop-description')
        .stop()
        .slideToggle('fast');
      });

      $drop_description
      .on('click', function(event){
        event.stopPropagation();
      });
    },
  }


/** Fancybox **/

  fx_fancy = {

    _construct: function(){
      this.$iframe = $("a.fbox-iframe");
      this.$image  = $("a.fbox-image");
    },

    //--

    iframe: function(){
      this._construct()
      this.$iframe

      //--

      .fancybox({
        type: 'iframe',
        autoResize: true,
        fitToView: true,
        aspectRatio: true,
      });
    },

    image: function(){
      this._construct();
      this.$image

      //--

      .fancybox({
        type: 'image',
        autoResize: true,
        fitToView: true,
        aspectRatio: true,
      });
    }
  }

/** Masonry **/

  fx_masonry = {

    _construct: function(){
      $msry_grid      = $('.msry-grid');
      msry_grid_item  = '.msry-grid-item';
    },

    _orderBy: function(){

      $msry_grid
      .masonry({
        itemSelector: msry_grid_item,
        //columnWidth: 200
      });
    },

    make: function(){
      this._construct();
      this._orderBy();

      //--

    }
  }

/** Map **/

  fx_map = {

    _construct: function(){
      defaultLatLng = {
        lat: 38.9972995,
        lng: -1.8744349
      };
      pin = '../../../assets/img/default/pin.png';
    },

    _initMap: function(){

      map = new google.maps.Map(document.getElementById('map'), {
        center: defaultLatLng,
        zoom: 18,
        scrollwheel: false,
        styles: [
          {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#5d7e9e"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                  {
                      "color": "#f2f2f2"
                  },
                  {
                      "saturation": "-100"
                  },
                  {
                      "lightness": "-1"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#f9f9f9"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "poi.attraction",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "poi.business",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "poi.park",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#e6f3d6"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "poi.park",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  },
                  {
                      "saturation": "-84"
                  },
                  {
                      "lightness": "0"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "saturation": "16"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "simplified"
                  },
                  {
                      "color": "#f6a8a8"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "labels.text",
              "stylers": [
                  {
                      "color": "#454545"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#fff0fa"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#777777"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                  {
                      "color": "#eaf6f8"
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#eaf6f8"
                  }
              ]
          }
        ],
      });

      marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        position: defaultLatLng,
        map: map,
        //icon: pin,
        title: "Benbunan Clínica Dental",
      });
    },

    draw: function(){
      this._construct();
      this._initMap();

      $(window)
      .resize(function(event){
        map.setCenter(defaultLatLng);
      });
    },
  }

  fx_tictactoe = {

  _construct: function() {
    _tictacThis = this;

    //--
    $services = $('.colored-squares .colored-square');
  },

  _draw: function() {
    window_width = $(window).width();

    service_item = 0;
    service_row  = 0;

    if(window_width >= 1024) {

      $services
      .each(function(i) {
        $this = $(this);

        if(service_item < 2) {
          $this.addClass('border-right');
        } else {
          $this.removeClass('border-right');
        }

        if(service_row >= 1) {
          $this.addClass('border-top');
        } else {
          $this.removeClass('border-top');
        }

        //--

        if(service_item < 2) {
          service_item = service_item + 1;
        } else {
          service_item = 0;
          service_row  = service_row + 1;
        }

      });

    }

    if(window_width <= 1023) {

      $services
      .each(function(i) {
        $this = $(this);

        if(service_item < 1) {
          $this.addClass('border-right');
        } else {
          $this.removeClass('border-right');
        }

        if(service_row >= 1) {
          $this.addClass('border-top');
        } else {
          $this.removeClass('border-top');
        }

        //--

        if(service_item < 1) {
          service_item = service_item + 1;
        } else {
          service_item = 0;
          service_row  = service_row + 1;
        }

      });

    }

    if(window_width <= 599) {

      $services
      .each(function(i) {
        $this = $(this);

        $this.removeClass('border-right');

        if(service_row > 0) {
          $this.addClass('border-top');
        }

        //--

        service_item = service_item + 1;
        service_row  = service_row + 1;
      });
    }

    //--
  },

  init: function() {
    this._construct();

    //--
    _tictacThis._draw();

    $(window)
    .resize(function() {
      _tictacThis._draw();
    });
  }

}
